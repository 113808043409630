<template>
  <label>
    {{textPassword}}
    <div class="wrapper">
      <input class="form-input" ref="password" v-if="!showPassword" :id="passwordId" :name="passwordFormName" minlength="6" maxlength="500" required="" type="password" v-model="password" @focus="showHint=true" @blur="showHint=false" data-lpignore="true">
      <input class="form-input" ref="password" v-if="showPassword" :id="passwordId" :name="passwordFormName" minlength="6" maxlength="500" required="" type="text" v-model="password" @focus="showHint=true" @blur="showHint=false" data-lpignore="true">
      
      <div class="showHidePassword">
        <a href="#" role="button" @click.stop.prevent="toggleShow()">
          <i class="fal" :class="showPassword? 'fa-eye-slash':'fa-eye'" :title="showPassword ? textShow : textHide"></i>
        </a>
      </div>
    </div>
    
    <div>
      <div class="hint" :class="{show:showHint && hintMessage}"><strong>{{textHint}}:</strong> <span v-html="hintMessage" /></div>  
    </div>

    <div id="app" :class="'strength-'+strengthClass[strength]">
        <div class="border strength-meter bg-gray3" >
          <div class="filled"></div>
        </div>
        <div class="flex-center-between strength-text font-12px">
          <div>
            <div>{{textPasswordStrength}}: <span class="strength-text bold">{{strengthLabels[strength]}}</span></div>        
          </div>          
        </div>
    </div>
  </label>
  
</template> 

<script>
// const DEBOUNCE_TIME = 500;

export default {
  name: 'app',
  props:[
    'password-id',
    'password-form-name',
    'text-password-strength',
    'text-weak',
    'text-nopassword',
    'text-veryweak',
    'text-moderate',
    'text-strong',
    'text-password',
    'text-show',
    'text-hide',
    'text-hint',
    'text-hint-very-weak',
    'text-hint-weak',
    'text-hint-moderate-missing-number',
    'text-hint-moderate-missing-special', 
    'text-max-length-reached',
  ],
  data:function(){
    return {
      password:'', 
      
      showPassword: false,
      
      strength:'nopassword',

      strengthClass:{
        'nopassword':"nopassword",
        'veryWeak':"veryweak",
        'weak':"weak",
        'moderateMissingNumber':"moderate",
        'moderateMissingSpecial':"moderate",
        'strong':"strong",
      },
      strengthLabels:{
        'nopassword': this.textNopassword,
        'veryWeak': this.textVeryweak,
        'weak': this.textWeak,
        'moderateMissingNumber': this.textModerate,
        'moderateMissingSpecial': this.textModerate,
        'strong': this.textStrong,
      },
      numChars: 0,
      numNumericChars: 0, 
      specialCharLength: 0,
      showHint: false, 
      pawnCheckCached:[],
      debounceTimerRef: undefined, 
    }
  }, 
  computed:{ 
    hintMessage(){ 
      let msg = '';
      if (!this && !this.strength){ 
        return '';
      }
      if (this.strength === 'veryWeak'){ 
        msg = this.textHintVeryWeak;
      }else if (this.strength === 'weak'){ 
        msg = this.textHintWeak;
      }else if (this.strength === 'moderateMissingNumber') { 
        msg = this.textHintModerateMissingNumber;
      }else if (this.strength === 'moderateMissingSpecial') { 
        msg = this.textHintModerateMissingSpecial;
      }

      if (this.password.length >= 500){ 
        if (msg){ 
          msg += '<br>' + this.textMaxLengthReached;
        }else{ 
          msg = this.textMaxLengthReached;
        }
      }
      return msg;
    }
  },  
  watch:{
    password:function(p){
      const specialChars =  /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/gi;
      
      this.numChars = p?p.length:0;

      let matchedNumericChars = p.match(/[0-9]/gi, "");
      this.numNumericChars = matchedNumericChars?matchedNumericChars.length:0;

      let matchedSpecialChars =p.match(specialChars); 
      this.specialCharLength = matchedSpecialChars?matchedSpecialChars.length:0;

      if (this.numChars === 0){
        this.strength = 'nopassword';
      }else if (this.numChars < 6){
        this.strength = 'veryWeak';
      }else if (this.numNumericChars == 0 && this.specialCharLength == 0){
        this.strength = 'weak';
      }else if (this.numNumericChars == 0 && this.specialCharLength >= 1){
        this.strength = 'moderateMissingNumber';
      }else if (this.numNumericChars >= 1 && this.specialCharLength == 0){
        this.strength = 'moderateMissingSpecial';
      }else{
        this.strength = 'strong';
      }
    }
  },
  methods:{ 
    toggleShow() { 
      this.showPassword = !this.showPassword;
      this.$nextTick(()=>{ 
        this.$refs.password.focus();
      });
    }
  }
}
</script>

<style lang="scss">
#app{
  margin-top:-0.35rem;
}

  .strength-nopassword{
    .strength-meter .filled{
        width: 5%;
        background-color:#999;
    }
    .strength-text{
      color:#555;
    }
  }
  .strength-veryweak{
    .strength-meter .filled{
        background-color:#ce0037 ;
        width: 25%;
    }
    
    .strength-text{
      color:darken(#ce0037 ,30%);
    }
  }
  .strength-weak{
    .strength-meter .filled{
        width: 50%;
        background-color:#e27c00;
    }
    
    .strength-text{
      color:darken(#e27c00,30%) ;
    }
  }
  .strength-moderate{
    .strength-meter .filled{
        width: 75%;
        background-color:#ffbf3f;
    }
    .strength-text{
      color:darken(#ffbf3f,30% );
    }
  }
  .strength-strong{
    .strength-meter .filled{
        width: 100%;
        background-color:#6bcaba;
    }
    .strength-text{
      color:darken(#6bcaba, 30%);
    }
  }


.strength-text{
  font-weight:300;
}
.strength-meter{
  font-weight:normal;
  display:flex;
  .filled{
    height:.25rem;
    display:inline-block;
    transition: 0.3s all ease;
  }
}

label{
  position:relative;
}
.showHidePassword{
  font-weight:normal;
}

.hint { 
  padding: 0.5em;
  position: absolute;
  left: 0;
  z-index:10;
  border: 1px solid rgba(0,0,0,0.05);
  border-radius: 0.25em;
  box-shadow: 0 0 15px rgba(0,0,0,0.05);
  margin-top:2em;
  width:100%;
  background:#333;
  color:white;
  font-weight:normal;
  opacity: 0;
  transition: 0.2s all ease;
  pointer-events: none;
  &.show{
    opacity: 1;
  }

  &::before{ 
    content:'';
    display:block;
    position:absolute;
    z-index:10;
    border-top: 5px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #333;
    top:-10px;
    left:2em;
  }
}
.wrapper { 
  position:relative;
}
.showHidePassword { 
  position: absolute;
  right:0.75em;
  top:50%;
  line-height:1em;
  margin-top:-0.5em;
}
input.form-input { 
  padding-right:2em;
}
</style>
