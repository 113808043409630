import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

const mountEl = document.querySelector('#password-strength-app');

new Vue({
  render: createElement => {
    const context = { 
      props: {...mountEl.dataset}
    };
    return createElement(App, context)
  },
}).$mount('#password-strength-app')
